<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('company.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('company.pageTitle'),
                        href: '/admin/companies'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <div class="row">
                    <div class="col-md-5 tw-mb-5">
                        <Profile
                            v-model="model"
                            :validate="errors"
                            class="tw-h-full"
                            ref="profile"
                        />
                    </div>
                    <div class="col-md-7 tw-mb-5">
                        <ts-panel>
                            <ts-panel-wrapper style="height: 671px;">
                                <Info v-model="model" :validate="errors" />
                            </ts-panel-wrapper>
                        </ts-panel>
                    </div>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'company' })"
                class="btn-gray"
            >
                {{ $t("cancel") }}</ts-button
            >
            <ts-button
                :waiting="waiting"
                @click.prevent="onUpdate"
                color="primary"
            >
                {{ $t("update") }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import Profile from "./components/profile.vue";
import Info from "./components/info.vue";
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";
import { mapActions, mapState } from "vuex";

export default {
    name: "companyEdit",
    components: {
        Profile,
        Info
    },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            model: {
                company_name_kh: "",
                company_name_en: "",
                company_address: "",
                company_phone: "",
                company_email: "",
                company_website: "",
                company_logo: "",
                prefix_code: "",
                company_mission: "",
                company_vision: "",
                core_values: ""
            }
        };
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    computed: {
        ...mapState("corporate/company", ["edit_data"])
    },
    methods: {
        ...mapActions("corporate/company", ["find"]),
        async fetchResource() {
            await this.find(this.$route.params.id).catch(error => {
                this.notice({ type: "error", text: error.message });
            });
            this.setEditData();
        },
        async onUpdate() {
            this.errors = new Errors();
            this.waiting = true;
            let file_upload_path = await this.$refs.profile.uploadLogo();
            this.$store
                .dispatch("corporate/company/update", {
                    id: this.model.company_id,
                    data: Object.assign({}, this.model, {
                        company_logo: file_upload_path
                    })
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$router.push({ name: "company" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.company_id = this.edit_data.company_id;
                this.model.company_name_kh = this.edit_data.company_name_kh;
                this.model.company_name_en = this.edit_data.company_name_en;
                this.model.company_address = this.edit_data.company_address;
                this.model.company_phone = this.edit_data.company_phone;
                this.model.company_email = this.edit_data.company_email;
                this.model.company_website = this.edit_data.company_website;
                this.model.company_logo = this.edit_data.company_logo;
                this.model.prefix_code = this.edit_data.prefix_code;
                this.model.company_mission = this.edit_data.company_mission;
                this.model.company_vision = this.edit_data.company_vision;
                this.model.core_values = this.edit_data.core_values;
                this.$refs.profile.loadLogoFileSource();
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "COMPANY",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        next();
    }
};
</script>
